<template>
    <div class="abs-full flex flex-yc flex-xc">
        <div class="b-box">
            <p class="b-title flex flex-yc flex-sb">{{type=='dep'?'出港':'进港'}}受影响的航班 <span @click="close"
                    class="icon-close iconfont"></span></p>
            <div class="tab-box">
                <table class="b-table" cellpadding="0" cellspacing="0" border="0">
                    <thead>
                        <tr>
                            <th>航班号</th>
                            <th>出发机场</th>
                            <th>到达机场</th>
                            <th>计划起飞时间</th>
                            <th>计划到达时间 </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in flights" :key="index">
                            <td>{{item[0]}}</td>
                            <td>{{item[1]}}</td>
                            <td>{{item[2]}}</td>
                            <td>{{item[3]}}</td>
                            <td>{{item[4]}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="mask abs-full"></div>
    </div>

</template>
<script>
    export default {
        props: {
            flights: {
                type: Object,
            },
            type: {
                type: String,
                default: 'dep'
            }
        },
        setup(props, context) {
            const close = () => {
                context.emit('close')
            }
            return {
                close
            }
        }

    }

</script>
<style scoped lang="less">
    .mask {
        background: rgba(0, 0, 0, .5);
    }

    .b-box {
        background: #fff;
        width: 80%;
        position: relative;
        z-index: 10;
        height: 75%;
        overflow: hidden;

        .iconfont {
            cursor: pointer;
            font-size: 22px;
        }

        .b-title {
            font-weight: bold;
        }
    }

    .tab-box {
        height: 90%;
        overflow-y: scroll;


    }
</style>